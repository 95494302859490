import React from 'react'
import { Helmet } from 'react-helmet'
import ScrollToTop from '../../BaseComponent/ScrollToTop/ScrollToTop'

function TermsOfUse() {
    return (
        <>
            <Helmet>
                <title>Terms of Use | Total Gaming Hub</title>
                <meta name="description" content="Play the Cool Online Games for Kids; Play Ben 10 Action Games, Adventure Time and Free Fire Game. Choose from over many Free Kids Games at Here! We add new games every day. Have fun!" />
            </Helmet>
            <ScrollToTop />
            <div className='w-full flex justify-center px-[20px]'>
                <div className='containers'>
                    <div className='grid-cols-12 grid gap-5 my-[35px] bg-[#222341] border-2 border-[#404380] md:p-[30px] p-[20px] rounded-[24px]'>
                        <div className="col-span-12">
                            <h1 className='sm:text-2xl text-xl text-white text-center pb-4 border-b-2 border-[#404380]'>Terms of Use</h1>
                            <div className="text-white mt-8">
                                <ul className='list-disc sm:text-base text-sm ms-5'>
                                    <li className='leading-8 my-[10px]'>
                                        Welcome to total gaming hub! ('we', 'our' or 'us') operate a website, accessible at https://totalgaminghub.com.
                                        In this document we explain the terms of use for our total gaming hub Website.
                                        Please click this link for an overview of the domains referred to as "total gaming hub Website".
                                        The total gaming hub Website may include Web pages which permit individuals to access virtual worlds,
                                        games and other content, along with downloadable software or applications to be used on personal computers,
                                        tablets, mobile devices, or phones. All visitors of the total gaming hub Website (the "Website Visitors") are subject to these Terms of Use.
                                    </li>
                                    <li className='leading-8 my-[10px]'>
                                        Please read these terms of use carefully before accessing or using any part of this total gaming hub website.
                                        By accessing or using this total gaming hub website, you agree that you have read,
                                        understand and agree to be bound by these terms of use, as amended.
                                    </li>
                                    <li className='leading-8 my-[10px]'>
                                        The terms and conditions as stated above are subject to amendment and update by total gaming hub at anytime it sees fit,
                                        and if such is the case, then we will post the revised terms of use on the total gaming hub Website.
                                        By your continued use of the total gaming hub Website, you would have accepted and agreed with us on the said revision of
                                        the terms and conditions. In case you do not agree with the terms of use or are not satisfied with the total gaming hub
                                        website altogether, your sole and exclusive remedy is to discontinue using this total gaming hub website.
                                    </li>
                                </ul>
                            </div>
                            <div className="text-white mt-7">
                                <ul className='list-decimal sm:text-base text-sm ms-8'>
                                    <li className='leading-8 my-[8px]'>Age</li>
                                    <li className='leading-8 my-[8px]'>Use of this website</li>
                                    <li className='leading-8 my-[8px]'>Privacy: collection and use of personal information</li>
                                    <li className='leading-8 my-[8px]'>Uploads to your devices</li>
                                    <li className='leading-8 my-[8px]'>Data charges</li>
                                    <li className='leading-8 my-[8px]'>Copyright restrictions/use of content</li>
                                    <li className='leading-8 my-[8px]'>Software and downloads</li>
                                    <li className='leading-8 my-[8px]'>Access and interference</li>
                                    <li className='leading-8 my-[8px]'>Trademarks</li>
                                    <li className='leading-8 my-[8px]'>Interaction with other users</li>
                                    <li className='leading-8 my-[8px]'>Indemnification</li>
                                    <li className='leading-8 my-[8px]'>Comments and submissions</li>
                                    <li className='leading-8 my-[8px]'>Links to other websites</li>
                                    <li className='leading-8 my-[8px]'>Copyright and other intellectual property infringement</li>
                                    <li className='leading-8 my-[8px]'>Choice of law</li>
                                    <li className='leading-8 my-[8px]'>Miscellaneous</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TermsOfUse