import React, { useEffect, useState } from 'react'
import time from "../../Assets/image/time.png";
import noBanner from "../../Assets/image/demo banner.png";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getBlogDetails, getGameDetails, randomGameList } from '../../Service/ApiServices';
import toast from 'react-hot-toast';
import { IMG_URL, IMGS_URL } from '../../Service/Api';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import right from "../../Assets/image/arrow-right.png";
import left from "../../Assets/image/arrow-left.png";
import ScrollToTop from '../../BaseComponent/ScrollToTop/ScrollToTop';
import { Helmet } from 'react-helmet';

function BlogDetails() {

    const navigate = useNavigate()

    const { name } = useParams()

    const [loader, setLoader] = useState(false)

    const [gameDetails, setGameDetails] = useState({})

    const handleBlogInfo = () => {
        setLoader(true)
        getBlogDetails(name)
            .then((res) => {
                console.log("res >>", res)
                const data = res.data.info
                setGameDetails(data)
                setLoader(false)
            })
            .catch((err) => {
                const errs = err.response.data
                const status = err.response.status
                if (status === 422) {
                    toast.error(errs.errors[0].msg)
                } else {
                    toast.error(errs.Message)
                }
                setLoader(false)
            })
    }

    useEffect(() => {
        handleBlogInfo()
    }, [])

    return (
        <>
            <Helmet>
                <title>{name || "Game Blog"} | Total Gaming Hub</title>
            </Helmet>
            <ScrollToTop />
            <div className='w-full flex justify-center lg:px-0 px-3'>
                <div className='containers'>
                    <div className='grid-cols-12 grid gap-5 my-[35px] bg-[#222341] border-2 border-[#404380] md:p-[30px] p-[20px] rounded-[24px]'>
                        {
                            loader ?
                                <SkeletonTheme
                                    baseColor="#ffffff10"
                                    highlightColor="#ffffff30"
                                    borderRadius="0.5rem"
                                    duration={1.5}
                                >
                                    <div className="col-span-12">
                                        <div className='w-full h-[400px] bg-cover bg-center rounded-[12px]'>
                                            <Skeleton height={400} className="rounded-[12px]" />
                                        </div>
                                        <div className="text-white mt-4">
                                            <Skeleton height={50} className="my-[10px]" />
                                            <Skeleton height={50} className="my-[10px]" />
                                            <Skeleton height={50} className="my-[10px]" />
                                            <Skeleton height={50} className="my-[10px]" />
                                            <Skeleton height={50} className="my-[10px]" />
                                            <Skeleton height={50} className="my-[10px]" />
                                        </div>
                                    </div>
                                </SkeletonTheme>
                                :
                                <div className="col-span-12">
                                    <div className="w-full flex justify-center mb-5 font-medium">
                                        <h3 className='text-3xl text-white'>{gameDetails.blog_title || ""}</h3>
                                    </div>
                                    <div className='w-full sm:h-auto h-[200px]'>
                                        <img src={gameDetails.blog_img ? IMGS_URL + gameDetails.blog_img : ""} className='w-full sm:h-auto h-[200px] rounded-[12px]' alt="" />
                                    </div>
                                    <div className="text-white mt-8">
                                        <p className='leading-8 my-[10px]'
                                            dangerouslySetInnerHTML={{
                                                __html: gameDetails.description,
                                            }}
                                        >
                                        </p>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default BlogDetails
