import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { gameUrlList } from '../../Service/ApiServices'
import toast from 'react-hot-toast'
import * as animationData from '../../Assets/Json/loader1.json'
import Lottie from 'react-lottie'

function GamePlay() {

    const [gameUrl, setGameUrl] = useState("")
    const [isLoader, setIsLoader] = useState(false);
    const [isStopped, setIsStopped] = useState(false);
    const { name } = useParams()

    useEffect(() => {
        handleGameInfo()
    }, [])


    const handleGameInfo = () => {
        setIsLoader(true)
        gameUrlList(name)
            .then((res) => {
                const data = res.data.info;
                setGameUrl(data.game_url)
                setIsLoader(false)
            })
            .catch((err) => {
                console.log("err >>", err)
                const errs = err.response.data
                const status = err.response.status
                if (status === 422) {
                    toast.error(errs.errors[0].msg)
                } else {
                    toast.error(errs.Message)
                }
                setIsLoader(false)
            })
    }

    const defaultOptions = {
        loop: isStopped,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <div className='relative'>
            <div className='flex justify-center absolute left-0 right-0 mt-[200px]'>
                <Lottie options={defaultOptions}
                    height={250}
                    width={250}
                />
            </div>
            <iframe src={gameUrl} className='w-full h-[100vh] absolute' frameborder="0"></iframe>
        </div>
    )
}

export default GamePlay