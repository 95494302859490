import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { addContactInfo } from '../../Service/ApiServices';
import toast from 'react-hot-toast';
import { ThreeCircles } from 'react-loader-spinner';
import ScrollToTop from '../../BaseComponent/ScrollToTop/ScrollToTop';

// Validation schema using Yup
const validationSchema = Yup.object({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string()
        .email('Invalid email address')
        .required('Email is required')
        .matches(/^[a-zA-Z0-9._%+-]+@gmail\.com$/, 'Email must be a valid Gmail address'),
    phone: Yup.string()
        .required('Phone number is required')
        .test('valid-phone', 'Invalid phone number', (value) => {
            const parsedPhone = parsePhoneNumberFromString(value);
            return parsedPhone && parsedPhone.isValid();
        }),
    descriptions: Yup.string().required('Descriptions are required')
});

function ContactUs() {

    const [isLoding, setIsLoding] = useState(false)

    const handleSubmit = (values, { resetForm }) => {
        setIsLoding(true);
        const parsedPhone = parsePhoneNumberFromString(values.phone);

        let obj = {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            iso_code: parsedPhone.country,
            country_code: parsedPhone.countryCallingCode,
            phoneNo: parsedPhone.nationalNumber,
            Descriptions: values.descriptions
        };

        addContactInfo(obj)
            .then((res) => {
                console.log("res >>", res);
                const data = res.data.Message
                toast.success(data);
                setIsLoding(false);
                resetForm();
            })
            .catch((err) => {
                console.log("err >>", err);
                const errs = err.response.data;
                const status = err.response.status;
                if (status === 422) {
                    toast.error(errs.errors[0].msg);
                } else {
                    toast.error(errs.Message);
                }
                setIsLoding(false);
            });
    };
    return (
        <>
            <Helmet>
                <title>ContactUs | Total Gaming Hub</title>
                <meta name="description" content="Contact Us For, If you are facing any problem to play our game" />
            </Helmet>
            <ScrollToTop />
            <div className='w-full flex justify-center px-[20px]'>
                <Formik
                    initialValues={{
                        firstName: '',
                        lastName: '',
                        email: '',
                        phone: '',
                        descriptions: ''
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ setFieldValue, values }) => (
                        <Form autoComplete="off">
                            {/* Hidden fields to prevent autofill */}
                            <input type="text" name="fakeusernameremembered" style={{ display: 'none' }} />
                            <input type="password" name="fakepasswordremembered" style={{ display: 'none' }} />

                            <div className='containers'>
                                <div className='grid-cols-12 grid gap-5 my-[35px] bg-[#222341] border-2 border-[#404380] md:p-[30px] p-[20px] rounded-[24px]'>
                                    <div className="col-span-12">
                                        <h1 className='text-2xl text-white text-center pb-4 border-b-2 border-[#404380]'>Contact Us</h1>
                                    </div>
                                    <div className="col-span-12 flex flex-wrap justify-center">
                                        <div className="m-3 xl:w-[400px] w-full">
                                            <p className='text-lg text-white mb-2'>First Name</p>
                                            <div className='bg-[#222341] border-2 border-[#404380] flex items-center w-full py-[15px] px-[20px] rounded-full'>
                                                <Field type="text" name="firstName" placeholder='Enter first name' autoComplete='off' className='w-full text-lg bg-[#222341] outline-none text-white' />
                                            </div>
                                            <ErrorMessage name="firstName" component="div" className='text-red-500 pt-2' />
                                        </div>
                                        <div className="m-3 xl:w-[400px] w-full">
                                            <p className='text-lg text-white mb-2'>Last Name</p>
                                            <div className='bg-[#222341] border-2 border-[#404380] flex items-center w-full py-[15px] px-[20px] rounded-full'>
                                                <Field type="text" name="lastName" placeholder='Enter last name' autoComplete='off' className='w-full text-lg bg-[#222341] outline-none text-white' />
                                            </div>
                                            <ErrorMessage name="lastName" component="div" className='text-red-500 pt-2' />
                                        </div>
                                        <div className="m-3 xl:w-[400px] w-full">
                                            <p className='text-lg text-white mb-2'>Email</p>
                                            <div className='bg-[#222341] border-2 border-[#404380] flex items-center w-full py-[15px] px-[20px] rounded-full'>
                                                <Field type="email" name="email" placeholder='Enter email' autoComplete='off' className='w-full text-lg bg-[#222341] outline-none text-white' />
                                            </div>
                                            <ErrorMessage name="email" component="div" className='text-red-500 pt-2' />
                                        </div>
                                        <div className="m-3 xl:w-[400px] w-full">
                                            <p className='text-lg text-white mb-2'>Phone Number</p>
                                            <div>
                                                <PhoneInput
                                                    defaultCountry="in"
                                                    className='bg-[#222341] border-2 border-[#404380] flex items-center w-full py-[15px] px-[20px] rounded-full'
                                                    value={values.phone}
                                                    onChange={(phone) => setFieldValue('phone', phone)}
                                                    autoComplete='off'
                                                />
                                            </div>
                                            <ErrorMessage name="phone" component="div" className='text-red-500 pt-2' />
                                        </div>
                                        <div className="m-3 xl:w-[824px] w-full">
                                            <p className='text-lg text-white mb-2'>Descriptions</p>
                                            <div className='bg-[#222341] border-2 border-[#404380] flex items-center w-full py-[15px] px-[20px] rounded-[12px]'>
                                                <Field as="textarea" name="descriptions" placeholder='Enter type...' rows={5} autoComplete='off' className='w-full text-lg bg-[#222341] outline-none text-white' />
                                            </div>
                                            <ErrorMessage name="descriptions" component="div" className='text-red-500 pt-2' />
                                        </div>
                                        <button type='submit' className='m-3 xl:w-[400px] w-full bg-white py-[15px] rounded-full font-medium text-lg'>
                                            {
                                                isLoding ?
                                                    <div className='flex justify-center'>
                                                        <ThreeCircles
                                                            width={27}
                                                            height={27}
                                                            color="#131432"
                                                            ariaLabel="circles-loading"
                                                            wrapperStyle={{}}
                                                            visible={true}
                                                            wrapperClass=""
                                                        />
                                                    </div>
                                                    : "Submit"
                                            }
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
}

export default ContactUs;
