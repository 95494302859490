import React, { useState } from "react";
import logo from "../../Assets/image/logos.png";
import home from "../../Assets/image/home.png";
import receipt from "../../Assets/image/receipt-search.png";
import crown from "../../Assets/image/message-text.png";
import sms from "../../Assets/image/note.png";
import information from "../../Assets/image/information.png";
import book from "../../Assets/image/book.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AlignJustify, X, ArrowLeft } from 'lucide-react';

const SideBar = () => {

    const navigate = useNavigate()
    const location = useLocation()

    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <nav className="shadow bg-[#26284C] h-[100px] relative">
            </nav>
            <div className="4xl:flex 2xl:flex xl:block lg:block hidden containers mx-auto -mt-[50px] relative 2xl:justify-between justify-center items-center bg-[#131432] border-2 border-[#404380] rounded-[24px] px-[20px]">
                <div onClick={() => { navigate("/home") }} className="xl:flex xl:justify-center flex justify-center cursor-pointer my-[20px]">
                    <img src={logo} className="w-[130px] h-[61px]" alt="logo" />
                </div>
                <div className="items-center xl:flex xl:justify-center flex justify-center my-[20px]">
                    <Link to={"/home"} className={`2xl:px-[20px] px-[12px] mx-[5px] py-[10px] font-medium rounded-full ${location.pathname == "/home" || location.pathname == "/" || location.pathname == "/home/" ? "bg-white text-[#222341]" : "bg-[#222341] text-white"}`}>
                        <div className="flex items-center whitespace-nowrap">
                            <img src={home} className="2xl:w-6 2xl:h-6 w-5 h-5 mr-2" alt="" />
                            <p className="2xl:text-base text-[15px]">Home</p>
                        </div>
                    </Link>
                    <Link to={"/new_game"} className={`2xl:px-[20px] px-[12px] mx-[5px] py-[10px] font-medium rounded-full ${location.pathname == "/new_game" || location.pathname == "/new_game/" ? "bg-white text-[#222341]" : "bg-[#222341] text-white"}`}>
                        <div className="flex items-center whitespace-nowrap">
                            <img src={receipt} className="2xl:w-6 2xl:h-6 w-5 h-5 mr-2" alt="" />
                            <p className="2xl:text-base text-[15px]">New Game</p>
                        </div>
                    </Link>
                    <Link to={"/blog"} className={`2xl:px-[20px] px-[12px] mx-[5px] py-[10px] font-medium rounded-full ${location.pathname == "/blog" || location.pathname == "/blog/" ? "bg-white text-[#222341]" : "bg-[#222341] text-white"}`}>
                        <div className="flex items-center whitespace-nowrap">
                            <img src={crown} className="2xl:w-6 2xl:h-6 w-5 h-5 mr-2" alt="" />
                            <p className="2xl:text-base text-[15px]">Blog</p>
                        </div>
                    </Link>
                    <Link to={"/privacy_policy"} className={`2xl:px-[20px] px-[12px] mx-[5px] py-[10px] font-medium rounded-full ${location.pathname == "/privacy_policy" || location.pathname == "/privacy_policy/" ? "bg-white text-[#222341]" : "bg-[#222341] text-white"}`}>
                        <div className="flex items-center whitespace-nowrap">
                            <img src={book} className="2xl:w-6 2xl:h-6 w-5 h-5 mr-2" alt="" />
                            <p className="2xl:text-base text-[15px]">Privacy Policy</p>
                        </div>
                    </Link>
                    <Link to={"/terms_of_use"} className={`2xl:px-[20px] px-[12px] mx-[5px] py-[10px] font-medium rounded-full ${location.pathname == "/terms_of_use" || location.pathname == "/terms_of_use/" ? "bg-white text-[#222341]" : "bg-[#222341] text-white"}`}>
                        <div className="flex items-center whitespace-nowrap">
                            <img src={sms} className="2xl:w-6 2xl:h-6 w-5 h-5 mr-2" alt="" />
                            <p className="2xl:text-base text-[15px]">Terms</p>
                        </div>
                    </Link>
                    <Link to={"/aboutus"} className={`2xl:px-[20px] px-[12px] mx-[5px] py-[10px] font-medium rounded-full ${location.pathname == "/aboutus" || location.pathname == "/aboutus/" ? "bg-white text-[#222341]" : "bg-[#222341] text-white"}`}>
                        <div className="flex items-center whitespace-nowrap">
                            <img src={information} className="2xl:w-6 2xl:h-6 w-5 h-5 mr-2" alt="" />
                            <p information="2xl:text-base text-[15px]">About Us</p>
                        </div>
                    </Link>
                </div>
            </div>
            <div className="w-full flex justify-center">
                <div className="containers lg:mx-auto mx-3 lg:hidden -mt-[50px] relative h-[100px] flex justify-between items-center bg-[#131432] border-2 border-[#404380] rounded-[24px] p-[20px]">
                    <div>
                        <button onClick={() => setIsOpen(!isOpen)} className="border rounded-[11px] p-2">
                            {isOpen ?
                                <span className="text-white"><X /></span>
                                : <span className="text-white"><AlignJustify /></span>}
                        </button>
                    </div>
                    <div>
                        <img src={logo} onClick={() => { navigate("/home") }} className="sm:w-[130px] w-[100px] sm:h-[61px] h-[47px]" alt="logo" />
                    </div>
                </div>
            </div>
            {isOpen && <div className="fixed inset-0 bg-[#0000004D] lg:hidden z-10 backdrop-blur-sm" onClick={() => setIsOpen(false)}></div>}
            <div
                className={`fixed p-7 top-0 left-0 h-full bg-[#131432] z-20 transform overflow-auto sm:w-[300px] w-[80%] ${isOpen ? "translate-x-0" : "-translate-x-full"
                    } transition-transform duration-500 ease-in-out lg:hidden`}
            >
                <div className="flex justify-center items-center">
                    <div>
                        <img src={logo} onClick={() => { navigate("/home"); setIsOpen(false) }} className="sm:w-[130px] w-[100px] sm:h-[61px] h-[47px]" alt="logo" />
                    </div>
                    <div className="ms-auto">
                        <div onClick={() => setIsOpen(false)} className="text-white cursor-pointer">
                            <ArrowLeft />
                        </div>
                    </div>
                </div>
                <nav className="mt-5">
                    <div className={`px-[20px] py-[13px] font-medium rounded-full my-10 ${location.pathname == "/home" || location.pathname == "/" || location.pathname == "/home/" ? "bg-white text-[#222341]" : "bg-[#222341] text-white"}`}>
                        <Link to={"/home"} onClick={() => setIsOpen(false)} className="flex items-center whitespace-nowrap">
                            <img src={home} className="w-6 h-6 mr-2" alt="" />
                            <p className="text-base">Home</p>
                        </Link>
                    </div>
                    <div className={`px-[20px] py-[13px] font-medium rounded-full my-10 ${location.pathname == "/new_game" || location.pathname == "/new_game/" ? "bg-white text-[#222341]" : "bg-[#222341] text-white"}`}>
                        <Link to={"/new_game"} onClick={() => setIsOpen(false)} className="flex items-center whitespace-nowrap">
                            <img src={receipt} className="w-6 h-6 mr-2" alt="" />
                            <p className="text-base">New Game</p>
                        </Link>
                    </div>
                    <div className={`px-[20px] py-[13px] font-medium rounded-full my-10 ${location.pathname == "/blog" || location.pathname == "/online_game/" ? "bg-white text-[#222341]" : "bg-[#222341] text-white"}`}>
                        <Link to={"/blog"} onClick={() => setIsOpen(false)} className="flex items-center whitespace-nowrap">
                            <img src={crown} className="w-6 h-6 mr-2" alt="" />
                            <p className="text-base">Blog</p>
                        </Link>
                    </div>
                    <div className={`px-[20px] py-[13px] font-medium rounded-full my-10 ${location.pathname == "/privacy_policy" || location.pathname == "/privacy_policy/" ? "bg-white text-[#222341]" : "bg-[#222341] text-white"}`}>
                        <Link to={"/privacy_policy"} onClick={() => setIsOpen(false)} className="flex items-center whitespace-nowrap">
                            <img src={book} className="w-6 h-6 mr-2" alt="" />
                            <p className="text-base">Privacy Policy</p>
                        </Link>
                    </div>
                    <div className={`px-[20px] py-[13px] font-medium rounded-full my-10 ${location.pathname == "/terms_of_use" || location.pathname == "/terms_of_use/" ? "bg-white text-[#222341]" : "bg-[#222341] text-white"}`}>
                        <Link to={"/terms_of_use"} onClick={() => setIsOpen(false)} className="flex items-center whitespace-nowrap">
                            <img src={sms} className="w-6 h-6 mr-2" alt="" />
                            <p className="text-base">Terms</p>
                        </Link>
                    </div>
                    <div className={`px-[20px] py-[13px] font-medium rounded-full my-10 ${location.pathname == "/aboutus" || location.pathname == "/aboutus/" ? "bg-white text-[#222341]" : "bg-[#222341] text-white"}`}>
                        <Link to={"/aboutus"} onClick={() => setIsOpen(false)} className="flex items-center whitespace-nowrap">
                            <img src={information} className="w-6 h-6 mr-2" alt="" />
                            <p information="text-base">About Us</p>
                        </Link>
                    </div>
                </nav>
            </div>
        </>
    );
};

export default SideBar;
