import React, { useEffect, useState } from 'react'
import searchicon from "../../Assets/image/search-normal.png";
import console from "../../Assets/image/console.png";
import right from "../../Assets/image/arrow-right.png";
import left from "../../Assets/image/arrow-left.png";
import { Link, useNavigate } from 'react-router-dom';
import { getBlogList } from '../../Service/ApiServices';
import { IMGS_URL } from '../../Service/Api';
import toast from 'react-hot-toast';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import ScrollToTop from '../../BaseComponent/ScrollToTop/ScrollToTop';
import { Helmet } from 'react-helmet';

function Blog() {

    const navigate = useNavigate()

    const [pageNo, setPageNo] = useState(1)
    const [totalPage, setTotalPage] = useState()
    const [searchText, setSearchText] = useState("")
    const [loader, setLoader] = useState(false)
    const [blogData, setBlogData] = useState([])

    const handleBlogInfo = () => {
        setLoader(true)
        let obj = {
            page: pageNo
        }
        getBlogList(obj)
            .then((res) => {
                const data = res.data.info
                setBlogData(data)
                setTotalPage(res.data.totalPages)
                setLoader(false)
            })
            .catch((err) => {
                const errs = err.response.data
                const status = err.response.status
                if (status === 422) {
                    toast.error(errs.errors[0].msg)
                } else {
                    toast.error(errs.Message)
                }
                setLoader(false)
            })
    }

    useEffect(() => {
        handleBlogInfo()
    }, [pageNo])

    const renderPaginationButtons = () => {
        const maxPagesToShow = 3;
        const buttons = [];
        const startPage = Math.max(1, pageNo - Math.floor(maxPagesToShow / 2));
        const endPage = Math.min(totalPage, startPage + maxPagesToShow - 1);

        for (let i = startPage; i <= endPage; i++) {
            buttons.push(
                <button
                    key={i}
                    onClick={() => handlePageChange(i)}
                    className={i === pageNo ? 'bg-[#222341] border-2 border-[#404380] text-white rounded-[10px] w-[40px] h-[40px] mx-2 font-medium text-base' : 'text-white px-3 py-1 mx-2'}
                >
                    {i}
                </button>
            );
        }
        return buttons;
    };

    const handlePageChange = (page) => {
        if (page > 0 && page <= totalPage) {
            setPageNo(page);
        }
    };

    return (
        <>
            <Helmet>
                <title>Blog | Total Gaming Hub</title>
                <meta name="description" content="total gaming hub is the #1 website for playing free online games on your mobile, tablet, mobile or computer. No downloads, no login. Play now!" />
            </Helmet>
            <ScrollToTop />
            <div className='w-full flex justify-center lg:px-0 px-3'>
                <div className='containers'>
                    <div className='grid grid-cols-12 sm:gap-8 gap-4 my-[30px] bg-[#222341] border-2 border-[#404380] md:p-[30px] p-[20px] rounded-[24px]'>
                        <div className='col-span-12'>
                            <h1 className='sm:text-2xl text-xl text-white text-center pb-4 border-b-2 border-[#404380]'>Blog</h1>
                        </div>
                        {
                            loader ?
                                <SkeletonTheme
                                    baseColor="#ffffff10"
                                    highlightColor="#ffffff30"
                                    borderRadius="0.5rem"
                                    duration={1.5}
                                >
                                    {Array(9).fill().map((_, index) => (
                                        <div key={index} className="xl:col-span-4 md:col-span-6 col-span-12">
                                            <Skeleton height={190} />
                                            <Skeleton height={20} style={{ marginTop: 10 }} />
                                        </div>
                                    ))}
                                </SkeletonTheme>
                                :
                                <>
                                    {
                                        blogData.length === 0 ?
                                            <div className='col-span-12 h-[60vh] flex justify-center items-center'>
                                                <div>
                                                    <div className='flex justify-center'>
                                                        <img src={console} className='w-20' alt="" />
                                                    </div>
                                                    <p className='text-lg mt-3 text-[#8c8dab] font-medium'>No Data Found</p>
                                                </div>
                                            </div>
                                            :
                                            <>
                                                {blogData.map((item, index) => {
                                                    const formattedTitle = item.blog_title.replace(/\s+/g, '-');
                                                    return (
                                                        <Link key={index} to={`/blog_details/${formattedTitle}`} className="hover:scale-105 duration-200 cursor-pointer xl:col-span-4 md:col-span-6 col-span-12">
                                                            <div className='flex justify-center'>
                                                                <img src={item.blog_img ? IMGS_URL + item.blog_img : ""} className='sm:h-[250px] w-full sm:h-[250px] h-[200px] rounded-[20px] object-cover' alt="" />
                                                            </div>
                                                            <h1 className='text-white sm:text-lg text-base font-medium text-center mt-3 '>{item.blog_title || "-"}</h1>
                                                        </Link>
                                                    )
                                                })}
                                            </>
                                    }
                                </>
                        }
                    </div>
                    {
                        blogData.length === 0 ? ""
                            :
                            <>
                                {
                                    totalPage <= 1 ? ""
                                        :
                                        <div className='grid grid-cols-12 my-[30px] bg-[#222341] border-2 border-[#404380] p-[13px] rounded-[12px]'>
                                            <div className='col-span-12 flex justify-center items-center'>
                                                <div className='flex items-center cursor-pointer'>
                                                    <button onClick={() => handlePageChange(pageNo - 1)} disabled={pageNo === 1}>
                                                        <img src={left} className='w-[24px] h-[24px]' alt="" />
                                                    </button>
                                                    <div className="flex items-center">
                                                        {renderPaginationButtons()}
                                                    </div>
                                                    <button onClick={() => handlePageChange(pageNo + 1)} disabled={pageNo === totalPage}>
                                                        <img src={right} className='w-[24px] h-[24px]' alt="" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                }
                            </>
                    }
                </div>
            </div>
        </>
    )
}

export default Blog