import React from 'react'
import { Helmet } from 'react-helmet'
import ScrollToTop from '../../BaseComponent/ScrollToTop/ScrollToTop'

function AboutUs() {
    return (
        <>
            <Helmet>
                <title>AboutUs | Total Gaming Hub</title>
                <meta name="description" content="Learn aboutus Totalgamin journey in offering modern, engaging games with no downloads, no login. Join us and play for free!" />
            </Helmet>
            <ScrollToTop />
            <div className='w-full flex justify-center px-[20px]'>
                <div className='containers'>
                    <div className='grid-cols-12 grid gap-5 my-[35px] bg-[#222341] border-2 border-[#404380] md:p-[30px] p-[20px] rounded-[24px]'>
                        <div className="col-span-12">
                            <h1 className='sm:text-2xl text-xl text-white text-center pb-4 border-b-2 border-[#404380]'>About</h1>
                            <div className="text-white mt-8">
                                <h1 className='font-medium sm:text-lg text-base gradient-background p-[14px] rounded-[14px]'>Free Play Game On Totalgaminhub.com</h1>
                                <ul className='list-disc sm:text-base text-sm ms-5'>
                                    <li className='leading-8 my-[10px]'>
                                        You can play all our <a href='https://totalgaminghub.com/home' target='_blank' className='font-semibold text-[#0084ff]'>games</a> without downloads, login, popups or other distractions.
                                        You can enjoy playing fun games without interruptions from downloads or pop-ups.
                                    </li>
                                    <li className='leading-8 my-[10px]'>
                                        <a href='https://totalgaminghub.com/home' target='_black' className='font-semibold text-[#0084ff]'>Total gaming hub</a> has the <a href='https://totalgaminghub.com/online_game' target='_black' className='font-semibold text-[#0084ff]'>best free online games</a> selection and offers free online
                                        games with the best experience to play alone or with friends.
                                    </li>
                                </ul>
                            </div>
                            <div className="text-white mt-7">
                                <h1 className='font-medium sm:text-lg text-base gradient-background p-[14px] rounded-[14px]'>About Total Gaming Hub</h1>
                                <ul className='list-disc sm:text-base text-sm ms-5'>
                                    <li className='leading-8 my-[10px]'>
                                        <a href='https://totalgaminghub.com/home' target='_black' className='font-semibold text-[#0084ff]'>Total gaming hub</a> has developers working on our gaming platform. The mission of our platform is simple - to provide seamless gaming for users around the world and reward developers of all sizes.
                                    </li>
                                    <li className='leading-8 my-[10px]'>
                                        <a href='https://totalgaminghub.com/home' target='_black' className='font-semibold text-[#0084ff]'>Where Can You Play Our Game?</a> :- Our games are playable on desktop, tablet and mobile so you can enjoy them at school, at home or on the road. You can play our games on desktop mobile devices. That includes everything from desktop PCs, laptops, and Chromebooks, to the latest smartphones and tablets from Apple and Android.
                                    </li>
                                    <li className='leading-8 my-[10px]'>
                                        Thousands of gamers from around the world play their favourite games on <a href='https://totalgaminghub.com/home' target='_black' className='font-semibold text-[#0084ff]'>Total Gaming Hub</a> every month. Our goal is to create the best online playground. Free and open to all.
                                    </li>
                                    <li className='leading-8 my-[10px]'>
                                        We're a team of makers, techies, adventurers - and some gamers too. We're kids of all ages, and love what we do.
                                    </li>
                                    <li className='leading-8 my-[10px]'>
                                        You can <a href='https://totalgaminghub.com/home' target='_black' className='font-semibold text-[#0084ff]'>instantly access your favourite games</a> from your web browser.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutUs