import React, { useEffect, useState } from 'react'
import searchicon from "../../Assets/image/search-normal.png";
import console from "../../Assets/image/console.png";
import right from "../../Assets/image/arrow-right.png";
import left from "../../Assets/image/arrow-left.png";
import { Link, useNavigate } from 'react-router-dom';
import { newAllGameList } from '../../Service/ApiServices';
import { IMG_URL } from '../../Service/Api';
import toast from 'react-hot-toast';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import ScrollToTop from '../../BaseComponent/ScrollToTop/ScrollToTop';
import { Helmet } from 'react-helmet';

function NewGame() {

    const navigate = useNavigate()

    const [pageNo, setPageNo] = useState(1)
    const [totalPage, setTotalPage] = useState()
    const [searchText, setSearchText] = useState("")
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        handleGameInfo()
    }, [searchText])

    const [gameData, setGameData] = useState([])

    const handleGameInfo = () => {
        setLoader(true)
        newAllGameList(pageNo, searchText)
            .then((res) => {
                const data = res.data.info
                setGameData(data)
                setTotalPage(res.data.totalPages)
                setLoader(false)
            })
            .catch((err) => {
                const errs = err.response.data
                const status = err.response.status
                if (status === 422) {
                    toast.error(errs.errors[0].msg)
                } else {
                    toast.error(errs.Message)
                }
                setLoader(false)
            })
    }

    const renderPaginationButtons = () => {
        const maxPagesToShow = 3;
        const buttons = [];
        const startPage = Math.max(1, pageNo - Math.floor(maxPagesToShow / 2));
        const endPage = Math.min(totalPage, startPage + maxPagesToShow - 1);

        for (let i = startPage; i <= endPage; i++) {
            buttons.push(
                <button
                    key={i}
                    onClick={() => handlePageChange(i)}
                    className={i === pageNo ? 'bg-[#222341] border-2 border-[#404380] text-white rounded-[10px] w-[40px] h-[40px] mx-2 font-medium text-base' : 'text-white px-3 py-1 mx-2'}
                >
                    {i}
                </button>
            );
        }
        return buttons;
    };

    const handlePageChange = (page) => {
        if (page > 0 && page <= totalPage) {
            setPageNo(page);
        }
    };
    return (
        <>
            <Helmet>
                <title>New Game | Total Gaming Hub</title>
                <meta name="description" content="Play free online games at total gaming hub, the best place to play high-quality browser games. We add new games every day here. Have fun!" />
            </Helmet>
            <ScrollToTop />
            <div className='w-full flex justify-center lg:px-0 px-3'>
                <div className='containers'>
                    <div className='flex justify-center mt-[30px]'>
                        <div className='bg-[#222341] border-2 border-[#404380] flex items-center w-full py-[15px] px-[20px] rounded-full'>
                            <img src={searchicon} className='sm:w-6 sm:h-6 w-5 h-5 mr-3' alt="" />
                            <input type="search" onChange={(e) => setSearchText(e.target.value)} placeholder='Search' className='w-full text-lg bg-transparent outline-none text-white' />
                        </div>
                    </div>
                    <div className='grid grid-cols-12 gap-5 my-[30px] bg-[#222341] border-2 border-[#404380] md:p-[30px] p-[20px] rounded-[24px]'>
                        {
                            loader ?
                                <SkeletonTheme
                                    baseColor="#ffffff10"
                                    highlightColor="#ffffff30"
                                    borderRadius="0.5rem"
                                    duration={1.5}
                                >
                                    {Array(12).fill().map((_, index) => (
                                        <div key={index} className="col-span-6 sm:col-span-4 md:col-span-3 lg:col-span-3 xl:col-span-2">
                                            <Skeleton height={190} />
                                            <Skeleton height={20} style={{ marginTop: 10 }} />
                                        </div>
                                    ))}
                                </SkeletonTheme>
                                :
                                <>
                                    {
                                        gameData.length === 0 ?
                                            <div className='col-span-12 h-[60vh] flex justify-center items-center'>
                                                <div>
                                                    <div className='flex justify-center'>
                                                        <img src={console} className='w-20' alt="" />
                                                    </div>
                                                    <p className='text-lg mt-3 text-[#8c8dab] font-medium'>No Data Found</p>
                                                </div>
                                            </div>
                                            :
                                            <>
                                                {gameData.map((item, index) => {
                                                    const formattedTitle = item.game_title
                                                        .toLowerCase()
                                                        .replace(/[^a-z0-9]/g, "")
                                                    return (
                                                        <Link key={index} to={`/games/${formattedTitle}`} className="hover:scale-105 duration-200 cursor-pointer 4xl:col-span-2 2xl:col-span-2 xl:col-span-3 lg:col-span-3 md:col-span-4 sm:col-span-4 col-span-6">
                                                            <div className='flex justify-center'>
                                                                <img src={item.game_img ? IMG_URL + item.game_img : ""} className='sm:h-[190px] sm:w-[190px] w-[150px] h-[150px] rounded-[20px] object-cover' alt="" />
                                                            </div>
                                                            <h1 className='text-white sm:text-lg text-base font-medium text-center mt-3'>{item.game_title || "-"}</h1>
                                                        </Link>
                                                    )
                                                })}
                                            </>
                                    }
                                </>
                        }
                    </div>
                    {
                        gameData.length === 0 ? ""
                            :
                            <>
                                {
                                    totalPage <= 1 ? ""
                                        :
                                        <div className='grid grid-cols-12 my-[30px] bg-[#222341] border-2 border-[#404380] p-[13px] rounded-[12px]'>
                                            <div className='col-span-12 flex justify-center items-center'>
                                                <div className='flex items-center cursor-pointer'>
                                                    <button onClick={() => handlePageChange(pageNo - 1)} disabled={pageNo === 1}>
                                                        <img src={left} className='w-[24px] h-[24px]' alt="" />
                                                    </button>
                                                    <div className="flex items-center">
                                                        {renderPaginationButtons()}
                                                    </div>
                                                    <button onClick={() => handlePageChange(pageNo + 1)} disabled={pageNo === totalPage}>
                                                        <img src={right} className='w-[24px] h-[24px]' alt="" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                }
                            </>
                    }
                </div>
            </div>
            <div className='w-full flex justify-center bg-[#26284C] p-[20px]'>
                <div className='containers text-white'>
                    <h1 className='text-lg font-meidum my-[20px]'>
                        Total Gaming Hub, Free Online Games and Video Walkthrough
                    </h1>
                    <p className='my-[20px] leading-8 text-[15px]'>
                        Our website <a href="https://totalgaminghub.com/" target='_blank' className='font-semibold text-[#0084ff]'>total gaming hub</a> has everything you need,
                        whether you want a fun game app or an <a href="https://totalgaminghub.com/online_game" target='_blank' className='font-semibold text-[#0084ff]'>online game</a> you can play on your browser without downloading,
                        or you want a game walkthrough, guides or tips. A high-quality game is what we aim to choose every day,
                        including <a href="https://totalgaminghub.com/aboutus" target='_blank' className='font-semibold text-[#0084ff]'>Android and iOS games</a>, <a href="https://totalgaminghub.com/online_game" target='_blank' className='font-semibold text-[#0084ff]'>online games</a> that can be played on computers and mobile devices,
                        as well as many <a href="https://totalgaminghub.com/new_game" target='_blank' className='font-semibold text-[#0084ff]'>new and hot free game</a> walkthroughs, such as how to get 5 stars, how to complete all levels,
                        and other <a href="https://totalgaminghub.com/contactus" target='_blank' className='font-semibold text-[#0084ff]'>tips and tutorials</a>.
                    </p>
                    <p className='my-[20px] leading-8 text-[15px]'>
                        Here you can discover apps with tens of thousands of downloads, as well as online games played by thousands of people,
                        cheats, and game walkthroughs that will help you succeed. You can pick up one game from our free online games,
                        including arcade games, <a href="https://totalgaminghub.com/games/96" target='_blank' className='font-semibold text-[#0084ff]'>puzzle games</a>, <a href="https://totalgaminghub.com/games/28" target='_blank' className='font-semibold text-[#0084ff]'>multiplayer games</a>, <a href="https://totalgaminghub.com/games/24" target='_blank' className='font-semibold text-[#0084ff]'>car racing games</a>, skill and reaction games, <a href="https://totalgaminghub.com/games/35" target='_blank' className='font-semibold text-[#0084ff]'>sports games</a>,
                        and more.
                    </p>
                    <p className='my-[20px] leading-8 text-[15px]'>
                        I hope you can find a game that <a href="https://totalgaminghub.com/privacy_policy" target='_blank' className='font-semibold text-[#0084ff]'>meets your needs here</a>,
                        whether you want to play alone or challenge friends. Our goal is to make the website as fast,
                        good, and comfortable as possible.
                    </p>
                </div>
            </div>
        </>
    )
}

export default NewGame