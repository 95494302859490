export const BASE_URL = "https://totalgaminghub.com:5000"
export const IMG_URL = "https://eu2.contabostorage.com/608120d74d8a40d086d8ed4541fcd233:gmx/"
export const IMGS_URL = "https://totalgaminghub.com:5000/"

export const GET_ALL_GAME_API = BASE_URL + "/get_games"
export const NEW_ALL_GAME_API = BASE_URL + "/new_game"
export const ONLINE_ALL_GAME_API = BASE_URL + "/onlinegames"
export const GAME_DETAILS_API = BASE_URL + "/get_games_details"
export const RANDOM_GAME_API = BASE_URL + "/random_game_list"
export const GAME_URL_API = BASE_URL + "/game_details_url"
export const CONTACT_US_API = BASE_URL + "/Contact_Us"
export const GET_BLOG_API = BASE_URL + "/get_blogs"
export const GET_BLOG_DETAILS_API = BASE_URL + "/blog_by_title"
