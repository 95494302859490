import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from '../Page/Home/Home'
import MainLayouts from '../Layouts/MainLayouts/MainLayouts'
import GameDetails from '../Page/GameDetails/GameDetails'
import AboutUs from '../Page/AboutUs/AboutUs'
import PrivacyPolicy from '../Page/PrivacyPolicy/PrivacyPolicy'
import NewGame from '../Page/NewGame/NewGame'
import OnlineGame from '../Page/OnlineGame/OnlineGame'
import ContactUs from '../Page/ContactUs/ContactUs'
import GamePlay from '../Page/GamePlay/GamePlay'
import Blog from '../Page/Blog/Blog'
import BlogDetails from '../Page/BlogDetails/BlogDetails'
import TermsOfUse from '../Page/TermsOfUse/TermsOfUse'

function Router() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='*' element={<MainLayouts><Home /></MainLayouts>} />
                <Route path='/home' element={<MainLayouts><Home /></MainLayouts>} />
                <Route path='/new_game' element={<MainLayouts><NewGame /></MainLayouts>} />
                <Route path='/online_game' element={<MainLayouts><OnlineGame /></MainLayouts>} />
                <Route path='/gameplay/:name' element={<GamePlay />} />
                <Route path='/contactus' element={<MainLayouts><ContactUs /></MainLayouts>} />
                <Route path='/games' element={<MainLayouts><GameDetails /></MainLayouts>} />
                <Route path='/games/:name' element={<MainLayouts><GameDetails /></MainLayouts>} />
                <Route path='/aboutus' element={<MainLayouts><AboutUs /></MainLayouts>} />
                <Route path='/privacy_policy' element={<MainLayouts><PrivacyPolicy /></MainLayouts>} />
                <Route path='/blog' element={<MainLayouts><Blog /></MainLayouts>} />
                <Route path='/blog_details/:name' element={<MainLayouts><BlogDetails /></MainLayouts>} />
                <Route path='/terms_of_use' element={<MainLayouts><TermsOfUse /></MainLayouts>} />
            </Routes>
        </BrowserRouter>
    )
}

export default Router