import React from 'react'
import { Helmet } from 'react-helmet'
import ScrollToTop from '../../BaseComponent/ScrollToTop/ScrollToTop'

function PrivacyPolicy() {
    return (
        <>
            <Helmet>
                <title>Privacy Policy | Total Gaming Hub</title>
                <meta name="description" content="Play the Cool Online Games for Kids; Play Ben 10 Action Games, Adventure Time and Free Fire Game. Choose from over many Free Kids Games at Here! We add new games every day. Have fun!" />
            </Helmet>
            <ScrollToTop />
            <div className='w-full flex justify-center px-[20px]'>
                <div className='containers'>
                    <div className='grid-cols-12 grid gap-5 my-[35px] bg-[#222341] border-2 border-[#404380] md:p-[30px] p-[20px] rounded-[24px]'>
                        <div className="col-span-12">
                            <h1 className='sm:text-2xl text-xl text-white text-center pb-4 border-b-2 border-[#404380]'>Privacy Policy & Terms Of TotalGamingHub</h1>
                            <div className="text-white mt-8">
                                <ul className='list-disc sm:text-base text-sm ms-5'>
                                    <li className='leading-8 my-[10px]'>
                                        Welcome to <a href="https://totalgaminghub.com/" target='_blank' className='font-semibold text-[#0084ff]'>total gaming hub</a>. Our terms of service & <a href="https://totalgaminghub.com/privacy_policy" target='_blank' className='font-semibold text-[#0084ff]'>privacy policy</a> guid to the use of our website totalgaminghub.com and any related services.
                                    </li>
                                    <li className='leading-8 my-[10px]'>
                                        By utilising our website, you are agreeing to abide by the terms outlined in this policy. We kindly request that you carefully examine this document to fully understand how we collect, use, and protect your information.
                                    </li>
                                </ul>
                            </div>
                            <div className="text-white mt-7">
                                <h1 className='font-medium sm:text-lg text-base gradient-background p-[14px] rounded-[14px]'>Use of Data</h1>
                                <ul className='list-decimal sm:text-base text-sm ms-5'>
                                    <li className='leading-8 my-[10px]'>
                                        To provide and maintain our website.
                                    </li>
                                    <li className='leading-8 my-[10px]'>
                                        To manage your account and affiliate partner program participation.
                                    </li>
                                    <li className='leading-8 my-[10px]'>
                                        To communicate with you  <a href="https://totalgaminghub.com/aboutus" target='_blank' className='font-semibold text-[#0084ff]'>about</a> your account, inquiries, and updates.

                                    </li>
                                    <li className='leading-8 my-[10px]'>
                                        To improve the usability and performance of our website.

                                    </li>
                                    <li className='leading-8 my-[10px]'>
                                        To comply with legal obligations.
                                    </li>
                                </ul>
                            </div>
                            <div className="text-white mt-7">
                                <h1 className='font-medium sm:text-lg text-base gradient-background p-[14px] rounded-[14px]'>Third-Party Services</h1>
                                <ul className='list-disc sm:text-base text-sm ms-5'>
                                    <li className='leading-8 my-[10px]'>
                                        We do not permit third parties to directly access or handle data, however,
                                        we may utilise third-party payment processors to assist with transactions and payments made on our website.
                                        Kindly refer to their individual privacy policies for further details.
                                    </li>
                                </ul>
                            </div>
                            <div className="text-white mt-7">
                                <h1 className='font-medium sm:text-lg text-base gradient-background p-[14px] rounded-[14px]'>Location of Data Processing</h1>
                                <ul className='list-disc sm:text-base text-sm ms-5'>
                                    <li className='leading-8 my-[10px]'>
                                        Your data will be processed in the European Union, where our servers are located.
                                    </li>
                                </ul>
                            </div>
                            <div className="text-white mt-7">
                                <h1 className='font-medium sm:text-lg text-base gradient-background p-[14px] rounded-[14px]'>Children's Privacy</h1>
                                <ul className='list-disc sm:text-base text-sm ms-5'>
                                    <li className='leading-8 my-[10px]'>
                                        <a href="https://totalgaminghub.com/" target='_blank' className='font-semibold text-[#0084ff]'>Our website</a> is accessible to children; however, it is not designed or intended for children below the age of 13.
                                        We do not purposefully collect personal information from children under 13 years old.
                                        If you suspect that a child has disclosed their personal details to us, please contact us promptly,
                                        and we will promptly delete such information.
                                    </li>
                                </ul>
                            </div>
                            <div className="text-white mt-7">
                                <h1 className='font-medium sm:text-lg text-base gradient-background p-[14px] rounded-[14px]'>Data Collection</h1>
                                <ul className='list-disc sm:text-base text-sm ms-5'>
                                    <li className='leading-8 my-[10px]'>
                                        We don't collect any user information or data from users.
                                    </li>
                                </ul>
                            </div>
                            <div className="text-white mt-7">
                                <h1 className='font-medium sm:text-lg text-base gradient-background p-[14px] rounded-[14px]'>Security</h1>
                                <ul className='list-disc sm:text-base text-sm ms-5'>
                                    <li className='leading-8 my-[10px]'>
                                        We have put in place The level of customer service that was adequate,
                                        but could have been improved with more training. Security measures to safeguard your  <a href="https://totalgaminghub.com/" target='_blank' className='font-semibold text-[#0084ff]'>personal information </a>
                                        against unauthorised access, disclosure, modification, or loss. Nevertheless,
                                        it is important to note that no data transmission over the internet or storage system can be
                                        completely secure. Therefore, we advise you to exercise care and take necessary precautions
                                        while sharing your  <a href="https://totalgaminghub.com/online_game" target='_blank' className='font-semibold text-[#0084ff]'>information online</a>.
                                    </li>
                                </ul>
                            </div>
                            <div className="text-white mt-7">
                                <ul className='list-decimal sm:text-base text-sm ms-5'>
                                    <li className='leading-8 my-[10px]'>
                                        We improve our products and advertising by using Microsoft Clarity to see how you use our website. By using our site, you agree that we and Microsoft can collect and use this data.
                                    </li>
                                    <li className='leading-8 my-[10px]'>
                                        We partner with Microsoft Clarity and Microsoft Advertising to capture how you use and interact with our website through behavioral metrics, heatmaps,
                                        and session replay to improve and market our products/services. Website usage data is captured using first and third-party cookies
                                        and other tracking technologies to determine the popularity of products/services and online activity. Additionally,
                                        we use this information for site optimization, fraud/security purposes, and advertising.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PrivacyPolicy