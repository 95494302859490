import React from 'react'
import logo from "../../Assets/image/logos.png";
import { Link, useLocation, useNavigate } from 'react-router-dom';

function Footer() {
    const location = useLocation()
    const navigate = useNavigate()

    const handleMove = () => {
        navigate("/home")
    }
    return (
        <div className='bg-[#26284C] border-t border-[#404380] flex justify-center p-[20px]'>
            <div className='containers'>
                <div className='grid grid-cols-12 gap-4 text-white fontNew py-5 flex justify-center'>
                    <div className='sm:col-span-4 col-span-12'>
                        <img onClick={handleMove} src={logo} className='w-[128px] h-[60px] cursor-pointer' alt="" />
                    </div>
                    <div className='sm:col-span-4 col-span-12 sm:mt-0 mt-8'>
                        <h2 className='font-medium text-xl'>Games</h2>
                        <div className='my-5 leading-8 '>
                            <p><Link to={"/home"} className={`hover:text-[#0084ff] duration-200 ${location.pathname == "/home" || location.pathname == "/" ? "text-[#0084ff]" : "text-[#FFFFFF99]"}`}>Home</Link></p>
                            <p><Link to={"/new_game"} className={`hover:text-[#0084ff] duration-200 ${location.pathname == "/new_game" ? "text-[#0084ff]" : "text-[#FFFFFF99]"}`}>New Game</Link></p>
                            <p><Link to={"/online_game"} className={`hover:text-[#0084ff] duration-200 ${location.pathname == "/online_game" ? "text-[#0084ff]" : "text-[#FFFFFF99]"}`}>Online Game</Link></p>
                        </div>
                    </div>
                    <div className='sm:col-span-4 col-span-12'>
                        <h2 className='font-medium text-xl'>Quick Links</h2>
                        <div className='my-5 text-[#FFFFFF99] leading-8'>
                            <p><Link to={"/blog"} className={`hover:text-[#0084ff] duration-200 ${location.pathname == "/blog" ? "text-[#0084ff]" : "text-[#FFFFFF99]"}`}>Blog</Link></p>
                            <p><Link to={"/privacy_policy"} className={`hover:text-[#0084ff] duration-200 ${location.pathname == "/privacy_policy" ? "text-[#0084ff]" : "text-[#FFFFFF99]"}`}>Privacy Policy</Link></p>
                            <p><Link to={"/contactus"} className={`hover:text-[#0084ff] duration-200 ${location.pathname == "/contactus" ? "text-[#0084ff]" : "text-[#FFFFFF99]"}`}>Contact Us</Link></p>
                            <p><Link to={"/terms_of_use"} className={`hover:text-[#0084ff] duration-200 ${location.pathname == "/terms_of_use" ? "text-[#0084ff]" : "text-[#FFFFFF99]"}`}>Terms</Link></p>
                            <p><Link to={"/aboutus"} className={`hover:text-[#0084ff] duration-200 ${location.pathname == "/aboutus" ? "text-[#0084ff]" : "text-[#FFFFFF99]"}`}>About Us</Link></p>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-12 bg-[#26284C] fontNew">
                    <div className="col-span-12 flex justify-center">
                        <p className='text-[#fff]'>© 2024 Total Gaming Hub</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer